import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import BigModal from './BigModal.jsx';
import FeedModalSidebar from './FeedModalSidebar.jsx';
import * as cmd from '../../feed_redux/commands';
import ngDateFilter from '../../../../ngDateFilter';
// import './FeedModal.css';

const TIMESTAMP_FORMAT = 'MMM d, y \'at\' h:mma';

class VideoFeedModal extends React.Component {
  componentDidMount() {
    const {cmd, video} = this.props;
    cmd.fetchContextActions(video._links.context_actions.href, video._links.self.href);
    if (video._links.viewed) {
      cmd.viewed(video._links.viewed.href, video._links.self.href, video.view_count);
    }
  }

  _delete = () => {
    const {cmd, contextActions, video} = this.props;
    if (!contextActions.delete || !confirm('Are you sure?')) {
      return;
    }
    cmd.deleteVideo(video, contextActions.delete.href);
  };

  render() {
    const {cmd, comments, contextActions, creator, liked, likes, recipient, showcased, video, views, onPlay} = this.props;
    const modalContentOverlay = (
      <div className="BigModalOptionsPane">
        <div className="BigModalOptionsPane-content">
          <div className="dropdown">
            <a className="dropdown-toggle" data-toggle="dropdown">
            <i className="fa-regular fa-ellipsis txt-middle" style={{ lineHeight: "inherit" }} ></i>
            </a>
            <ul className="unstyled no-padding no-margin dropdown-menu">
              {
                !contextActions ? (
                  <li>
                    <span className="loading-gif" />
                  </li>
                ): null
              }
              {
                contextActions ? (
                  <li>
                    <a data-user-voice-report-abuse={video._links.self.href}>
                      <span className="fa fa-flag"/> Report Abuse
                    </a>
                  </li>
                ): null
              }
              {
                contextActions && contextActions.delete ? (
                  <li>
                    <a onClick={this._delete}>
                      <span className="fa fa-times" /> Delete this Video
                    </a>
                  </li>
                ): null
              }
            </ul>
          </div>
        </div>
      </div>
    );
    let modalContent = null;
    if (video.video_type === 'VideoBlob') {
      const videoStyle = {
        height: '100%',
        width: '100%',
      };
      modalContent = (
        <video controls controlsList="nodownload" preload="none" autoPlay src={video._links.direct_url.href} style={videoStyle} poster={video._links.thumbnail.href} onPlay={onPlay}>
          <source src={video._links.direct_url.href} type="video/mp4" />
        </video>
      );
    } else {
      modalContent = (
        <iframe className="FeedModalVideoIframe" allowFullScreen key={video._links.self.href} src={video._links.embed.href} />
      );
    }
    const formattedDate = ngDateFilter(video.created_at, TIMESTAMP_FORMAT);
    const modalSidebar = (
      <FeedModalSidebar
        activityUrl={video._links.self.href}
        author={creator}
        cmd={cmd}
        contextActions={contextActions}
        comments={comments}
        description={video.description}
        destination={video._links.destination}
        liked={liked}
        likes={likes}
        recipient={recipient}
        showcased={showcased}
        tagsUrl={video._links.tags.href}
        timestamp={formattedDate}
        views={views}
        onLike={cmd.like}
        onShowcase={cmd.showcase}
      />
    );
    return (
      <BigModal
        content={modalContent}
        contentHeight={720}
        contentWidth={1280}
        contentOverlay={modalContentOverlay}
        sidebar={modalSidebar}
        onCloseRequested={cmd.closeModal} />
    );
  }
}

function mapStateToProps(state, ownProps) {
  const updatedViewCount = state.feed.updatedViewCounts[ownProps.video._links.self.href];
  return {
    contextActions: state.feed.contextActions[ownProps.video._links.self.href],
    showcased: state.feed.showcased[ownProps.video._links.self.href],
    views: updatedViewCount ? updatedViewCount : ownProps.video.view_count || ownProps.video.views_count,
  };
}

function mapDispatch (dispatch) {
  return {
    cmd: bindActionCreators(cmd, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatch)(VideoFeedModal);
